import React, { useEffect, useState, useContext } from "react";
import Activity, { instanceOfActivity } from "shared-models/build/activities";
import ActivityTag from "shared-models/build/activity_tags";
import ActivityTags, { instanceOfActivityTag } from "shared-models/build/activity_tags";
import { FirebaseAuthContext } from "../../context/firebase-auth";
import { sendPostRequest } from "../../util/util";

const ActivityView = () => {
    const [activities, setActivities] = useState<Activity[]>([]);
    const [tagMap, setTagMap] = useState<{[key: number]: ActivityTag[]}>({});
    const user = useContext(FirebaseAuthContext);

    useEffect(() => {
        if (user !== null) {
            getActivities();
        }
    }, [user]);

    const getActivities = async () => {
        if (user === null) return;
        const [response, err] = await sendPostRequest(user, "/activity/get-activities", {}, "", "Failed to get activities");
        
        if ("Activities" in response) {
            setActivities(response.Activities);
        }

        if ("ActivityTags" in response) {
            const initObj : { [key: number]: ActivityTag[] } = {}
            const activityTagMap = response.ActivityTags?.reduce((obj : { [key: number]: ActivityTag[] }, actTag: ActivityTag) => {
                if (!instanceOfActivityTag(actTag)) return obj;
                
                if (actTag.ActivityId in obj) {
                    return {
                        ...obj,
                        [actTag.ActivityId]: [ ...obj[actTag.ActivityId], actTag ],
                    }
                } else {
                    return {
                        ...obj,
                        [actTag.ActivityId]: [actTag],
                    }
                }
            }, initObj);
            setTagMap(activityTagMap);
        }
    }

    return (
        <div>
            <h3 className="tw-text-lg tw-font-bold tw-my-4">
                Activities
            </h3>
            {
                activities.length > 0 ?
                <div className="tw-grid tw-grid-cols-4 md:tw-grid-cols-7 tw-gap-4 tw-items-center tw-mb-4 tw-font-bold">
                    <div>Id</div>
                    <div>Name</div>
                    <div>Description</div>
                    <div>Picture URL</div>
                    <div>Settings</div>
                    <div>Verified</div>
                    <div>Action</div>
                </div>
                :
                null
            }
            {
                activities.length > 0 ?
                <div>
                    {
                        activities.map((act) => {
                            if (!instanceOfActivity(act)) return null;
                            return (
                                <div className="tw-grid tw-grid-cols-4 md:tw-grid-cols-7 tw-gap-4 tw-items-center tw-py-4 tw-border-b-2">
                                    <div>{act.Id}</div>
                                    <div>{act.Name}</div>
                                    <div>{act.Description}</div>
                                    <div className="tw-break-words">{act.ActivityPictureUrl}</div>
                                    <div>{JSON.stringify(act.Settings)}</div>
                                    <div>{act.Verified ? "yes" : "no" }</div>
                                    <div>
                                        <a href={`/admin-team/modify-activity?data=${encodeURIComponent(JSON.stringify({ Activity: act, ActivityTags: tagMap[act.Id] }))}`}>
                                            <button className="tw-white-button">
                                                Modify Activity
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                :
                <div>
                    No activities.
                </div>
            }
            <div className="tw-mt-8">
            <a href="/admin-team/modify-activity">
                <button className="tw-primary-button">
                    Add Activity
                </button>
            </a>
            </div>

        </div>
    )
}

export default ActivityView;