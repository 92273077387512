"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfActivity = void 0;
;
const instanceOfActivity = (object) => {
    return ('Id' in object &&
        'ActivityPictureUrl' in object &&
        'Name' in object &&
        'Description' in object &&
        'Settings' in object &&
        'Verified' in object);
};
exports.instanceOfActivity = instanceOfActivity;
